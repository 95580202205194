import React from 'react'
import {useNavigate } from 'react-router-dom'
import './TopNavbar.css'

const Logout = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.setItem("user", "");
    navigate('/login');
  };

  return (
    <a className="menu__item" onClick={handleLogout}>Logout</a>
  );
};

export default Logout
